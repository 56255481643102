'use client';

import * as React from 'react';
import { User } from '@ensite/domain/lib/user';
import { AuthUser } from '@ensite/auth/lib/user';
import { Client } from '@ensite/domain/lib/clients';
import { Feature, getFeatureOptions, isFeatureEnabled } from '@ensite/domain/lib/clients/features';
import { mapAuthUserToUser } from '@ensite/auth/lib/server/user';
import { RootClientLayout } from '@ensite/next/lib/clients/layout';
import { RootLayoutProvider } from '@ensite/next/lib/root-layout-provider';
import { AppRouterCacheProvider } from '@mui/material-nextjs/v14-appRouter';
import { ApiClientConfig } from '@ensite/domain/lib/api';
import { PreventRouteChangeProvider } from '@ensite/sdk/lib/prevent-route-change/PreventRouteChangeProvider';
import { REFRESH_TOKEN_PATH, clientFirebaseOptions } from '../../consts';
import { getValidIdToken } from 'next-firebase-auth-edge/lib/next/client';
import { fetchApi } from '@ensite/api-client/lib/api';
import { getFeatureOption } from '@ensite/domain/lib/clients/features';

interface ServerLayoutProps {
  children: React.ReactNode;
  user: AuthUser | null;
  config: ApiClientConfig;
  fontStyle: string;
  client: Client;
  lang?: string;
  className?: string;
}

export default function ServerLayout({
  children,
  user: authUser,
  config,
  fontStyle,
  client,
  lang,
  className,
}: ServerLayoutProps) {
  const user = React.useMemo<User | null>(
    () => (authUser ? mapAuthUserToUser(authUser, client.id) : null),
    [authUser]
  );

  const getReauthToken = async () => {
    const response = await fetchApi<{ idToken: string }>(
      new URL(window.location.origin + REFRESH_TOKEN_PATH)
    );
    return response.idToken;
  };

  return (
    <AppRouterCacheProvider>
      <RootLayoutProvider
        className={className}
        darkModeEnabled={
          isFeatureEnabled(client.settings, Feature.DarkMode) &&
          getFeatureOptions(client.settings, Feature.DarkMode).enabled
        }
        analyticsProvider={
          getFeatureOption(client.settings, Feature.Analytics, 'provider', null) ?? undefined
        }
        head={
          <>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
            {client.settings.favicons && (
              <>
                <link
                  rel="apple-touch-icon"
                  sizes="180x180"
                  href={client.settings.favicons['180']}
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="32x32"
                  href={client.settings.favicons['32']}
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="16x16"
                  href={client.settings.favicons['16']}
                />
                <link rel="manifest" href={client.settings.favicons.webmanifest} />
              </>
            )}

            {/* PWA primary color */}
            <meta name="theme-color" content={client.settings.theme.palette.light.primary} />
            <meta
              name="msapplication-TileColor"
              content={client.settings.theme.palette.light.primary}
            />
          </>
        }
        lang={lang}
      >
        <PreventRouteChangeProvider>
          <RootClientLayout
            user={user}
            getToken={() => {
              return getValidIdToken({
                serverIdToken: authUser?.token ?? '',
                refreshTokenUrl: window.location.origin + REFRESH_TOKEN_PATH,
              });
            }}
            getReauthToken={getReauthToken}
            firebaseApiKey={clientFirebaseOptions.apiKey}
            config={config}
            fontStyle={fontStyle}
            client={client}
          >
            {children}
          </RootClientLayout>
        </PreventRouteChangeProvider>
      </RootLayoutProvider>
    </AppRouterCacheProvider>
  );
}
