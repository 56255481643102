import { getApiClientConfig as getConfig } from '@ensite/api-client/lib/clients/config';
import { ApiClientConfig } from '@ensite/domain/lib/api';
import { Locale } from '@ensite/domain/lib/locale';
import { CookieOptions } from '@ensite/platform';

export const cookieOptions: CookieOptions = {
  cookieName: 'WebClientAuthToken',
  cookieSignatureKeys: [process.env.COOKIE_SECRET_CURRENT!, process.env.COOKIE_SECRET_PREVIOUS!],
  cookieSerializeOptions: {
    httpOnly: true,
    maxAge: 12 * 60 * 60 * 24, // twelve days
    path: '/',
    sameSite: 'lax' as 'lax',
    secure: process.env.USE_SECURE_COOKIE === 'true',
  },
};

export const REFRESH_TOKEN_PATH = '/api/reauth';

export const ENSITE_INTERNAL_CLIENT_URL = process.env.ENSITE_INTERNAL_CLIENT_URL!;
export const REDIS_HOST = process.env.REDIS_HOST!;

export const clientFirebaseOptions = {
  apiKey: process.env.FIREBASE_API_KEY!,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN!,
  databaseURL: process.env.FIREBASE_DATABASE_URL!,
  projectId: process.env.FIREBASE_PROJECT_ID!,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID!,
};

export const API_URL = process.env.ENSITE_API_URL!;

export function getApiClientConfig(clientId: string, locale: Locale): ApiClientConfig {
  return getConfig(clientId, locale, {
    dashboardUrl: process.env.ENSITE_DASHBOARD_URL!,
    apiUrl: API_URL,
  });
}
