'use client';

import { ThemeRoot } from '@ensite/sdk/lib/themes/ThemeRoot';

interface StaticClientLayoutProps {
  children: JSX.Element;
}

export function StaticClientLayout({ children }: StaticClientLayoutProps) {
  return <ThemeRoot>{children}</ThemeRoot>;
}
